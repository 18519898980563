<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card class="primary--border" outlined>
                    <v-card-title class="title">
                        Department Title
                        <v-spacer></v-spacer>
                        <add-button permission="section-create" @action="form.dialog = true, $refs.form ? $refs.form.reset():''">Assign</add-button>
                        <!--<v-btn outlined @click="form.dialog = true" ma-0 small>New Section</v-btn>-->
                    </v-card-title>
                    <v-data-table
                            :headers="headers"
                            hide-default-footer
                            :items="form.items.data"
                            :options.sync="pagination"
                            :loading="form.loading">
                        <template  v-slot:item="{index, item}">
                            <tr>
                                <td>{{ index + form.items.meta.from }}</td>
                                <td class="text-xs-left">
                                    <strong>{{ item.name }}</strong> <br>
                                    <small>{{item.class_teacher ? item.class_teacher.full_name:''}}
                                    </small>
                                </td>
                                <td class="text-right">
                                    <delete-button permission="section-delete" @agree="form.delete(item.id)"/>
                                </td>
                            </tr>
                        </template>
                        <v-alert slot="no-results" :value="true" color="error" icon="warning">
                            Your search for "{{ search }}" found no results.
                        </v-alert>
                    </v-data-table>
                </v-card>
            </v-flex>
            <v-dialog v-model="form.dialog" persistent max-width="300px">
                <v-card>
                    <v-card-title class="primary white--text">
                        <span class="title">Add/Update</span>
                    </v-card-title>
                    <v-card-text class="pb-1 pt-4">
                        <v-form ref="form" @submit.prevent="save"
                                @keydown.native="form.errors.clear($event.target.name)" @keyup.enter="save"
                                lazy-validation>
                            <v-autocomplete
                                outlined dense
                                    v-model="form.teacher_id"
                                    :items="items"
                                    :loading="isLoading"
                                    :search-input.sync="search"
                                    hide-no-data
                                    item-text="Name"
                                    :error-messages="form.errors.get('teacher_id')"
                                    item-value="id"
                                    label="teacher"
                                    placeholder="Start typing to Search"
                                    return-object></v-autocomplete>

                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="warning" text @click="form.dialog = false, form.reset()">Close</v-btn>
                        <v-btn color="success" text @click="save">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'

    export default {
        data: () => ({
            isLoading: false,
            teachers: [],
            entries: [],
            form: new Form({
                name: '',
                code: '',
                batch_id: '',
                grade_id: '',
                teacher_id: ''
            }, '/api/section'),
            search: '',
            pagination: {
                rowsPerPage: 25
            },
            headers: [
                {text: '#', align: 'center', value: 'id', width: 10, sortable: false},
                {text: 'Name', align: 'left', value: 'name'},
                {text: 'Action', align: 'right', sortable: false, width: 200}
            ],

        }),

        computed: {
            ...mapState(['batch']),
            items() {
                return this.entries.map(entry => {
                    return Object.assign({}, entry, {Name: entry.name})
                })
            }
        },

        watch: {
            'pagination': function () {
                this.get();
            },
            'form.dialog': function (value) {
                if (value) {
                    this.form.batch_id = this.batch.id;
                    this.form.grade_id = this.$route.params.gradeId
                }
            },
            'batch': function (value) {
                this.get();
            },
            search(val) {
                if (!val) return;
                if (this.isLoading) return;

                this.isLoading = true;
                this.$rest.get('api/user-search/slim?type=teacher&search=' + val)
                    .then(({data}) => {
                        this.entries = data.data
                    }).catch(err => {
                }).finally(() => (this.isLoading = false))
            }
        },

        methods: {
            queryString() {
                let json = this.pagination;
                return '?' +
                    Object.keys(json).map(function (key) {
                        if (![null, undefined].includes(json[key]))
                            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                    }).join('&') + '&grade=' + this.$route.params.gradeId;
            },

            get(params) {
                if ([null, undefined].includes(this.batch.id)) return;
                let query = [null, undefined].includes(params) ? this.queryString() : params;
                this.form.get(null, query).then(({data}) => {
                    // console.log(tea);
                    // this.pagination.totalItems = data.meta.total
                }).finally(() => {
                })
            },


            save() {
                // console.log(this.form.teacher_id.id);
                this.form.teacher_id = this.form.teacher_id.id;
                this.form.store().then(() => {
                });
            },

        }
    }
</script>
<style lang="scss" scoped></style>